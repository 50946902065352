
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from "vue-property-decorator";
import { apiAnnouncementGetDetail,
         apiAnnouncementDetailUpdate,
         apiAnnouncementCreate } from '@/services/tpsApi/tpsApiAnnouncement';
import { apiCompanyGetListName } from '../services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '../services/tpsApi/tpsApiBrand';
//import { IDriverDetail } from '../services/tpsApi/models/driver';
import { arrangeUpdateData } from '../services/arrangeUpdateData';
import DatepickerLite from "vue3-datepicker-lite";
import WarningModal from '@/components/WarningModal.vue';

@Options({
  components: {
    WarningModal,
    DatepickerLite
  },
  emits: ["hide-form"]
})


export default class AnnouncementForm extends Vue {
    $refs!: {
        warning: WarningModal
    }

    @Prop({ required: true }) showForm: boolean = false;

    WRITE: number | undefined = undefined; 
    originalData/*: IDriverDetail*/ = {} as any;
    formData/*: IDriverDetail*/ = {} as any;
    actionType: string = "";
    announcementID: number = -1;
    companySelectOptions: {} = {};
    brandSelectOptions: {} = {};
    dateWarningText: string | null = null;
    companyWarning: string | null = null;
    brandWarning: string | null = null;

    startdate = {
        id: "startdate",
        class: "md-input myDateInput md-datepicker w-10-rem",
        placeholder: "開始日期",
        locale: {
          format: "YYYY-MM-DD",
          weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          todayBtn: "Today",
          clearBtn: "Clear",
          closeBtn: "Close",
        },
        changeEvent: (value) => {
          this.formData["start_date"] = value;
        }
    }

    async beforeMount() {
        this.WRITE = this.$store.getters.write;
    }

    async mounted() {
        this.companySelectOptions = await apiCompanyGetListName();
        this.brandSelectOptions = await apiBrandGetListName();
    }

    async initForm(pActionType, pAnnouncementID) {
        this.actionType = pActionType;
        this.announcementID = pAnnouncementID;

        if(pAnnouncementID) {
            this.formData = Object.assign({}, await apiAnnouncementGetDetail({id:pAnnouncementID}));
            this.originalData = Object.assign({}, this.formData);
        }
        else {
            this.formData = {} as any;
            //Default select first item
            this.formData['brand_id'] = Object.keys(this.brandSelectOptions)[0];
            this.formData['company_id'] = Object.keys(this.companySelectOptions)[0];
            //Default data
            this.formData["invalid"] = 0;
        }
    }

    getWriteProp() {
        if(this.actionType === 'create') return true;
        return this.WRITE===1?true:false;
    }

    datepickerActive(pID) {
        let datepicker = document.getElementById(pID)
        if(datepicker) (datepicker as HTMLFormElement).focus(); 
    }

    async updateDetail(pSave: boolean) {
        if(pSave && this.checkRequiredData()) {
            //Set default modified_time & modified_user
            let dt = new Date(); 
            this.formData["modified_time"] = `${
                (dt.getMonth()+1).toString().padStart(2, '0')}-${
                dt.getDate().toString().padStart(2, '0')}-${
                dt.getFullYear().toString().padStart(4, '0')} ${
                dt.getHours().toString().padStart(2, '0')}:${
                dt.getMinutes().toString().padStart(2, '0')}:${
                dt.getSeconds().toString().padStart(2, '0')}`;
            this.formData["modified_user"] = this.$store.getters.currentUser;
            if(this.actionType == "update") {
                let res = await apiAnnouncementDetailUpdate({ id: this.announcementID, 
                                                        data: arrangeUpdateData(this.formData,this.originalData)});
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }      
            else if(this.actionType == "create") {
                let res = await apiAnnouncementCreate(this.formData);
                if(res.msg === ("ok")) this.hideForm(true);
                else this.hideForm(false);
            }   
        }
        if(pSave == false) {
            this.hideForm(false);
        }
    }

    checkRequiredData(): boolean {
        if(this.formData["start_date"] == undefined || this.formData["start_date"] == "") {
            this.dateWarningText = "請輸入公告日期";
        }
        else this.dateWarningText = null;
        
        if(this.formData.company_id == 0 && this.formData.brand_id != 0) {
            this.brandWarning = "公司名稱欄位為「全部」時，此欄位只可選擇「全部」選項";
        }
        else if(this.formData.brand_id == 0 && this.formData.company_id != 0) {
            this.companyWarning = "品牌名稱欄位為「全部」時，此欄位只可選擇「全部」選項";
        }
        else {
            this.companyWarning = null;
            this.brandWarning = null;
        }
        
        if(this.dateWarningText || this.companyWarning || this.brandWarning) return false;
        return true;
    }

    showWarningModal() {
        if(JSON.stringify(this.originalData) !== JSON.stringify(this.formData)) {
            this.$refs.warning.setModalVisible(true);
        }
        else {
            this.updateDetail(false);
        }
    }

    handleCancel() {
        this.hideForm(false);
    }

    @Emit("hide-form")
    hideForm(pSuccess: boolean) {
        if(pSuccess) return { actionType: this.actionType, alertType:"success", msg:"成功儲存" };
    }
}
