
import { Options, Vue } from 'vue-class-component';
import{ apiAnnouncementGetListTotalNum,
        apiAnnouncementGetList } from '@/services/tpsApi/tpsApiAnnouncement';
import { IAnnouncementList } from '../services/tpsApi/models/announcement';
import { DEFAULT_START_PAGE, DEFAULT_ITEMS_PER_PAGE } from '@/services/common/IPaganation';
import { PAGENO } from '@/services/common/IPageNo.js';
import { exportFile } from '@/services/exportFile';
import Alert from '@/components/Alert.vue';
import AnnouncementSearchMenu from '@/components/AnnouncementSearchMenu.vue';
import Table from '@/components/Table.vue';
import AnnouncementForm from '@/components/AnnouncementForm.vue';
import Pagination from '@/components/Pagination.vue';

@Options({
  components: {
    Alert,
    AnnouncementSearchMenu,
    AnnouncementForm,
    Table,
    Pagination
  }
})
export default class Announcement extends Vue {
    $refs!: {
        form: AnnouncementForm,
        alert: Alert
    }

    TABLE_HEAD: {} = {
        id: "公告編號",
        company_id: "公司名稱",
        brand_id: "品牌名稱",
        title: "標題",
        content: "內文",
        start_date: "公告日期",
        duration: "公告期限",
        invalid: "狀態",
        modified_time: "最後執行時間",
        modified_user: "最後執行人員"
    };
    
    //Pagination prop
    pageTotalNum: number = 0;
    currentPage: number = DEFAULT_START_PAGE;
    currentLimit: number = DEFAULT_ITEMS_PER_PAGE;
    
    formVisible: boolean = false;
    announcementList: IAnnouncementList | Blob = [];
    filter_keyword: string | undefined = undefined;
    filter_company: number | undefined = undefined;
    filter_brand: number | undefined = undefined;
    filter_startdate: string = "";
    filter_enddate: string = "";
    pageNo: {} = PAGENO;
    
    async mounted() {
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        this.initPagination();
    }

    async initPagination() {
        this.pageTotalNum = await apiAnnouncementGetListTotalNum({  filter: this.filter_keyword,
                                                                    filter_company: this.filter_company,
                                                                    filter_brand: this.filter_brand,
                                                                    filter_startdate: this.filter_startdate,
                                                                    filter_enddate: this.filter_enddate });
    }

    async showForm(pData: { actionType: string, id: number | null }) {
        this.$refs.form.initForm(pData.actionType,pData.id);
        this.formVisible = true;
    }
    
    hideForm(pData: { actionType: string, alertType: string, msg: string}) {
        this.formVisible = false;
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
        this.refreshTabel({limit: this.currentLimit, page: this.currentPage});
        if(pData.actionType == "create") this.initPagination();
    }

    async refreshTabel(pData: {limit: number, page: number}) {
        if(pData && pData.limit) this.currentLimit = pData.limit;
        if(pData && pData.page) this.currentPage = pData.page;
        pData = Object.assign(pData, {  filter: this.filter_keyword,
                                        filter_company: this.filter_company,
                                        filter_brand: this.filter_brand,
                                        filter_startdate: this.filter_startdate,
                                        filter_enddate: this.filter_enddate });
        this.announcementList = await apiAnnouncementGetList(pData);
    } 

    async download(pData: { output: string }) {
        const res = await apiAnnouncementGetList({  output: pData.output, 
                                                    filter: this.filter_keyword,
                                                    filter_company: this.filter_company,
                                                    filter_brand: this.filter_brand,
                                                    filter_startdate: this.filter_startdate,
                                                    filter_enddate: this.filter_enddate});
        exportFile({ fileName: "公告列表", data: res });
    }

    doSearch(pData: {   filter_keyword: string, 
                        filter_company: number, 
                        filter_brand: number, 
                        filter_startdate: string,
                        filter_enddate: string }) {
        this.filter_keyword = pData.filter_keyword;
        this.filter_company = pData.filter_company;
        this.filter_brand = pData.filter_brand;
        this.filter_startdate = pData.filter_startdate;
        this.filter_enddate = pData.filter_enddate;
        this.refreshTabel({limit: DEFAULT_ITEMS_PER_PAGE, page: DEFAULT_START_PAGE});
        this.initPagination();
    }
    
    doAlert(pData: {alertType: string, msg: string}) {
        this.$refs.alert.doAlert(pData.alertType, pData.msg);
    } 
}
