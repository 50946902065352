
import { Options, Vue } from 'vue-class-component';
import { Prop, Emit } from 'vue-property-decorator';
import { apiUploadFile } from '../services/tpsApi/tpsApiImportByFile';
import { apiCompanyGetListName } from '@/services/tpsApi/tpsApiCompany';
import { apiBrandGetListName } from '@/services/tpsApi/tpsApiBrand';
import DatepickerLite from "vue3-datepicker-lite";

@Options({
  components: {
    DatepickerLite
  },
  emits: [
      "form",
      "alert",
      "export",
      "search"
  ]
})

export default class AnnouncementSearchMenu extends Vue {
  $refs!: {
        file
  }

  @Prop({ required: false }) create: boolean = true;
  @Prop({ required: false }) table: string = "";

  file: string | Blob = "";
  filter_keyword: string = "";
  filter_company: string = "";
  filter_brand: string = "";
  filter_startdate: string = "";
  filter_enddate: string = "";

  companySelectOptions: {} = {};
  brandSelectOptions: {} = {};
  anDateWarningText: string = "";

  //Date picker setting
  startdate_an = {
      id: "startdate_an",
      class: "md-input myDateInput md-datepicker w-10-rem",
      placeholder: "開始日期",
      locale: {
        format: "YYYY-MM-DD",
        weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        todayBtn: "Today",
        clearBtn: "Clear",
        closeBtn: "Close",
      },
      changeEvent: (value) => {
        this.filter_startdate = value;
        if(this.dateCheck("an")) this.search();
      }
  }
  enddate_an = {
      id: "enddate_an",
      class: "md-input myDateInput md-datepicker w-10-rem",
      placeholder: "結束日期",
      locale: {
        format: "YYYY-MM-DD",
        weekday: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
        todayBtn: "Today",
        clearBtn: "Clear",
        closeBtn: "Close",
      },
      changeEvent: (value) => {
        this.filter_enddate = value;
        if(this.dateCheck("an")) this.search();
      }
  }
  
  @Emit("form")
  showForm(): { actionType: string, ID: number | null } {
      return { actionType: "create", ID: null };
  }

  @Emit("alert")
  doAlert(pAlertType, pMsg): {alertType: string, msg: string} {
    return {alertType: pAlertType, msg: pMsg};
  }

  @Emit("export")
  exportFile() {
    return { output: "xlsx" }
  }

  @Emit("search") 
  search() {
    return {    filter_keyword: this.filter_keyword,
                filter_company: this.filter_company,
                filter_brand: this.filter_brand,
                filter_startdate: this.filter_startdate,
                filter_enddate: this.filter_enddate };
  }

  async mounted() {
    this.companySelectOptions = await apiCompanyGetListName();
    this.brandSelectOptions = await apiBrandGetListName();
  }
  
  datepickerActive(pID) {
    let datepicker = document.getElementById(pID)
    if(datepicker) (datepicker as HTMLFormElement).focus(); 
  }

  //Check if start date less than end date 
  dateCheck(dateColumn) {
    let anStart = new Date(this.filter_startdate);
    let anEnd = new Date(this.filter_enddate);
    if(anStart > anEnd) {
        this.anDateWarningText = "起始時間不能大於結束時間";
        return false;
    }   
    else if (this.filter_startdate && !this.filter_enddate ||
        !this.filter_startdate && this.filter_enddate) {
        this.anDateWarningText = "請輸入完整起迄時間";
        return false;
    }
    else {
        this.anDateWarningText = "";
        return true;
    }
  }

  importFile() {
    this.$refs.file.value = null;
    this.$refs.file.click();
  }
  
  async fileUpload(event) {
    this.file = this.$refs.file.files[0];
    let formData = new FormData();
    formData.append('file', this.file);
    console.log("file:",this.file);
    console.log("table:",this.table);
    const res = await apiUploadFile({table:this.table, data:formData});
    if(res.msg === ("ok")) this.doAlert("success", "上傳成功");
    else this.doAlert("failed", "上傳失敗:"+res.msg);
  }
}
