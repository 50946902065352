import {
  IAnnouncementList,
  IAnnouncementDetail,
  IAnnouncementGetListTotalNumRequestParams,
  IAnnouncementGetListRequestParams,
  IAnnouncementGetDetailRequestParams,
  IAnnouncementDetailUpdateRequestParams,
  IAnnouncementCreateResponseSchema,
  IAnnouncementDetailUpdateResponseSchema
} from './models/announcement';
import tpsApiInstance from './tpsApiBase';

const ANNOUNCEMENT_PATH = "/announcement";

export const apiAnnouncementGetListTotalNum = async(
  pData?: IAnnouncementGetListTotalNumRequestParams): Promise<number> => {
    let query: string = "?count=total";
    query += convertQueryValue(pData);
    const res = await tpsApiInstance.get(`${ANNOUNCEMENT_PATH}${query}`);
    console.log("total:",res?.data[0]?.total);
    return res?.data[0]?.total;
};

export const apiAnnouncementGetList = async(
  pData?: IAnnouncementGetListRequestParams): Promise<IAnnouncementList | Blob> => {

  let query: string = "?";
  query += convertQueryValue(pData);
  if(pData && pData.output) {
    query += `&output=${pData.output}`;
    console.log("query:",query);
    const res = await tpsApiInstance.get(`${ANNOUNCEMENT_PATH}${query}`,{responseType: 'blob'});
    return res?.data;
  }
  else {
    console.log(`${ANNOUNCEMENT_PATH}${query}`);
    const res = await tpsApiInstance.get(`${ANNOUNCEMENT_PATH}${query}`);
    return res?.data;
  }
};

export const apiAnnouncementGetDetail = async(
  pData: IAnnouncementGetDetailRequestParams): Promise<IAnnouncementList> => {
  console.log(`${ANNOUNCEMENT_PATH}/${pData.id}`);
  const res = await tpsApiInstance.get(`${ANNOUNCEMENT_PATH}/${pData.id}`);
  console.log(res.data);
  return res?.data;
};

export const apiAnnouncementCreate = async(
  pData: IAnnouncementList): Promise<IAnnouncementCreateResponseSchema> => {
  console.log(JSON.stringify(pData));
  const res = await tpsApiInstance.post(ANNOUNCEMENT_PATH, pData);
  return res?.data;
};

export const apiAnnouncementDetailUpdate = async(
  pData: IAnnouncementDetailUpdateRequestParams
  ): Promise<IAnnouncementDetailUpdateResponseSchema> => {
  console.log(JSON.stringify(pData.data));
  const res = await tpsApiInstance.post(`${ANNOUNCEMENT_PATH}/${pData.id}`, pData.data);
  return res.data;
};

function convertQueryValue(pData): string {
  let query = "";
  if(pData && pData.limit && pData.page) query += `limit=${pData.limit}&offset=${(pData.page-1)*pData.limit}`;  
  if(pData && pData.filter) query+= `&filter=${pData.filter}`;
  if(pData && pData.filter_company) query+= `&filter_company=${pData.filter_company}`;
  if(pData && pData.filter_brand) query+= `&filter_brand=${pData.filter_brand}`;
  if(pData && pData.filter_startdate && pData.filter_enddate) query+= `&startdate_ad=${pData.filter_startdate}&enddate_ad=${pData.filter_enddate}`;

  return query;
}